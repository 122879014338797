<template>
  <div>
    <h1>CTes que chegaram atrasados</h1>

    <v-btn
      color="error"
      class="ma-1"
      @click="$router.back()"
    >
      <v-icon>
        mdi-keyboard-return
      </v-icon>
    </v-btn>

    <v-btn
      color="warning"
      class="ma-1"
      @click="$router.push('/ctes')"
    >
      CTes
    </v-btn>

    <arrival-late-filter-form :searching="loading" @params="getCtes" />

    <br>

    <v-data-table
      :items="ctes"
      :headers="headers"
      :loading="loading"
      dense
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.number`]="{ item }">
        <span>
          <a :href="'/#/editar-cte/'+item.id" target="_blank">{{ item.number }}</a>
        </span>
      </template>

      <template v-slot:[`item.arrival`]="{ item }">
        <span>{{ item.arrival | moment("DD/MM/YYYY") }}</span>
      </template>

      <template v-slot:[`item.dpe`]="{ item }">
        <span>{{ item.dpe | moment("DD/MM/YYYY") }}</span>
      </template>

    </v-data-table>

  </div>
</template>

<script>

import http from '@/modules/http';
import moment from 'moment';
import ArrivalLateFilterForm from './components/ArrivalLateFilterForm.vue';

export default {
  components: { ArrivalLateFilterForm },
  data() {
    return {
      ctes: [],
      loading: false,
      headers: [
        {text: 'CTe', value: 'number'},
        {text: 'NF', value: 'nf'},
        {text: 'Chegada', value: 'arrival'},
        {text: 'DPE', value: 'dpe'},
      ]
    }
  },
  methods: {
    getCtes(e) {
      this.loading = true;

      http.get('api/ctes?arrivalLateFrom='+e.from+'&arrivalLateTo='+e.to+'&partner='+e.partner_id+'&allPartners='+e.all)
      .then(res => {
        this.loading = false;
        this.ctes = res.data;
      }).catch(err => {
        this.loading = false;
        this.$side({
          type    : 'error',
          msg     : 'Erro ao carregar ctes de',
          duration: 4000
        });
        console.log(err);
      })
    }
  },
}
</script>

<style scoped>

</style>