<template>
  <div>
    <h3>Filtro</h3>

    <v-form
      @submit.prevent="getCtes"
      ref="formFilterArrivalLate"
    >
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            label="Início"
            type="date"
            :rules="rules"
            v-model="params.from"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Final"
            type="date"
            :rules="rules"
            v-model="params.to"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-switch
            label="Todas as parceiras"
            v-model="params.all"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            label="Parceira"
            :rules="rules"
            v-model="params.partner_id"
            :items="partners"
            item-text="name"
            item-value="id"
            :loading="loading"
            v-if="!params.all"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn :loading="searching" color="primary" type="submit">Filtrar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

import http from "@/modules/http";
import partnersModule from '@/modules/partners';

  export default {
    props: [
      'searching',
    ],
    data() {
      return {
        rules: [v => !! v || 'Preencha este campo'],
        params: {
          all: false,
          from: '',
          to  : '',
          partner_id: null,
        },
        partners: [],
        loading: false,
      }
    },
    mounted () {
      this.getPartners();
    },
    methods: {
      getCtes() {
        if(this.$refs.formFilterArrivalLate.validate()) {
          this.$emit('params', this.params);
        }
      },
      async getPartners() {
        this.loading = true;
        this.partners = await partnersModule();
        this.loading = false;
      }
    },
  }
</script>

<style scoped>

</style>